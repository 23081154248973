body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.a-center {
  align-items: center;
}

button {
  border: none;
  background-color: transparent;
}

.disable {
  cursor: not-allowed;
  color: #C0C0C0;
}

.disable:hover {
  cursor: not-allowed;
  color: #C0C0C0;
}

.ant-table-tbody > tr > td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.App {
  /* text-align: center; */
  font-family:PingFangSC-Regular;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.system-title-container {
  height: 64px;
  background: #002140;
  margin-bottom: 15px;
  padding: 13px 0;
}
.system-title {
  color:  #FFFFFF;
  font-size: 19px;
  font-family: MyriadPro-Semibold;
  font-weight: 600;
  line-height: 45px;
}

.header-container {
  background: #ffffff;
  padding: 0;
  height: 94px;
  position: relative;
}
.header-user-container {
  position: absolute;
  top: 16px;
  right: 56px;
  font-weight: 400;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
}
.header-vertical-line {
  display: inline-block;
  width: 2px;
  height: 15px;
  margin: 0 9px;
  background: #E2E2E2;
  vertical-align: middle;
}
.header-logout-a {
  color: rgba(0,0,0,0.65);
}
.header-history-button {
  position: absolute;
  left: 26px;
  bottom: 22px;
  color: #000000;
  cursor: pointer;
}
.header-title-container {
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  left: 56px;
  bottom: 15px;
  color: #000000;
}
/* 
.edit-container {
  width: 456px;
  min-height: 600px;
  padding: 33px 38px;
  background: #fff;
}
.edit-container {
  min-height: 600px;
  padding: 33px 38px;
}
.edit-container-scroll {
  overflow: scroll;
  overflow-x: hidden;
  width: 456px;
  height: 600px;
  background: #fff;
  margin: 0 auto;
}
.edit-bottom-container {
  color: #7AB4EA;
  overflow: hidden;
  cursor: pointer;
}
.edit-bottom-item {
  min-width: 0;
  margin-right: 14px;
  float: left;
}
.edit-icon {
  margin-right: 6px;
}
.edit-item+.edit-item{
  margin-top: 15px;
}
.edit-input {
  width: 100%;
  height: 95px;
  padding: 10px;
  background:rgba(246,246,246,1);
  border: 0;
  border-radius:2px;
  color:rgba(0,0,0,0.85);
  line-height: 25px;
}

.edit-container .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 170px;
}
.edit-image-container {
  width: 100%;
  background: rgba(246,246,246,1);
}

.edit-finish-button {
  margin-top: 32px;
} */
/* desc-show.css */
.desc-show-item+.desc-show-item{
  margin-top: 17px;
}
.desc-image-container {
  text-align: center;
}
.desc-content{
  color:rgba(0,0,0,0.85);
  line-height:25px;
}
.desc-image {
  max-width: 360px;
}
.map .left-bar::-webkit-scrollbar {
  display: none;
}

.map .left-bar {
  -ms-overflow-style: none;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
}

.map .list-item {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  position: relative;
}

.map .list-item:hover {
  background-color: #e8e8e8;
}

.map .list-item.active {
  background-color: #e8e8e8;
}

.map .list-item .checked-icon {
  font-size: 18px;
  position: absolute;
  top: 14px;
  right: 10px;
  /* transform: translateY(-9px); */
}

.map .list-item .left-box {
  display: flex;
  width: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.map .list-item .num {
  font-size: 20px;
  font-family: tahoma, "microsoft yahei", "\5FAE\8F6F\96C5\9ED1"!important;
  width: 30px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.map .list-item.small .num {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}

.map .list-item .right-box {
  display: flex;
  flex-direction: column;
}

.map .list-item .title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}

.map .list-item.small .title {
  height: 30px;
  line-height: 30px;
  margin-bottom: 0px;
}

.map .list-item .description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}

.map .ant-pagination {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  margin-top: 10px;
}

.map .marker {
  position: absolute;
  border: 0px;
  padding: 0px;
  margin: 0px;
  top: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 54px;
  height: 385px;
  min-width: 54px;
  max-width: 54px;
  min-height: 385px;
  max-height: 385px;
}

.map .marker.num-1 {
  top: 0px;
}

.map .marker.num-2 {
  top: -35px;
}

.map .marker.num-3 {
  left: -70px;
}

.map .marker.num-4 {
  left: -105px;
}

.map .marker.num-5 {
  left: -140px;
}

.map .marker.num-6 {
  left: -175px;
}

.map .marker.num-7 {
  left: -210px;
}

.map .marker.num-8 {
  left: -245px;
}

.map .marker.num-9 {
  left: -280px;
}

.map .marker.num-10 {
  left: -315px;
}

.map .marker.active {
  left: -27px;
}
