/* desc-show.css */
.desc-show-item+.desc-show-item{
  margin-top: 17px;
}
.desc-image-container {
  text-align: center;
}
.desc-content{
  color:rgba(0,0,0,0.85);
  line-height:25px;
}
.desc-image {
  max-width: 360px;
}