body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.flex.column {
  flex-direction: column;
}

.flex.a-center {
  align-items: center;
}

button {
  border: none;
  background-color: transparent;
}

.disable {
  cursor: not-allowed;
  color: #C0C0C0;
}

.disable:hover {
  cursor: not-allowed;
  color: #C0C0C0;
}

.ant-table-tbody > tr > td {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}