
.system-title-container {
  height: 64px;
  background: #002140;
  margin-bottom: 15px;
  padding: 13px 0;
}
.system-title {
  color:  #FFFFFF;
  font-size: 19px;
  font-family: MyriadPro-Semibold;
  font-weight: 600;
  line-height: 45px;
}

.header-container {
  background: #ffffff;
  padding: 0;
  height: 94px;
  position: relative;
}
.header-user-container {
  position: absolute;
  top: 16px;
  right: 56px;
  font-weight: 400;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
}
.header-vertical-line {
  display: inline-block;
  width: 2px;
  height: 15px;
  margin: 0 9px;
  background: #E2E2E2;
  vertical-align: middle;
}
.header-logout-a {
  color: rgba(0,0,0,0.65);
}
.header-history-button {
  position: absolute;
  left: 26px;
  bottom: 22px;
  color: #000000;
  cursor: pointer;
}
.header-title-container {
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  left: 56px;
  bottom: 15px;
  color: #000000;
}